<template>
<div class=" mx-auto">
  <div>
    <Loading :isLoading= "this.isLoading" />
    <div>
      <form @submit="addBeneficiarie" @submit.prevent="abilityButtonNext" class="md:px-8 pt-6 pb-8 mb-4" style="text-align: left;">
        <br>
        <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
          <div class="row">
            <center>
              <p class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-5xl title" style="margin-top:0px;">
                  Datos de Beneficiarios</p>
            </center>
          </div>
          <h4 class="steps">Paso 6 de 10</h4>
        </div>
        <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
          <div class="row">
            <center>
                <!-- <p v-if="errors.length">
                <ul>
                    <li v-for="error in errors"><h5 style="color:red">{{ error }}</h5></li>
                </ul>
                </p> -->
                <br>
            </center>
          </div>
        </div>
        <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
          <div class="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lx:grid-cols-2">
            <div class="border">
              <div class="center">
                <div class="text-left my-3" style="text-align: left;margin-top:-15px; margin-top:5px;margin-right:10px">
                  <label class="label-input" style="font-size:15px;color:black;color: #6E6E6E;">Apellido Paterno:</label>
                  <input type="text"  id="Input_Paternal" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                  minlength="3" maxlength="30" pattern="[a-zA-Z-0-9éñúóáíÑÁÉÓÚÍ ]{3,30}" v-model="paternal" required>
                </div>
                <div class="text-left my-3" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                  <label class="label-input" style="font-size:15px;color:black;color: #6E6E6E;">Apellido Materno:</label>
                  <input type="text" id="Input_Maternal" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                  minlength="3" maxlength="30" pattern="[a-zA-Z-0-9éñúóáíÑÁÉÓÚÍ ]{3,30}" v-model="maternal" >
                </div>
                <div class="text-left my-3" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                  <label class="label-input" style="font-size:15px;color:black;color: #6E6E6E;">Primer Nombre:</label>
                  <input type="text" id="Input_FirstName" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                  minlength="3" maxlength="30" pattern="[a-zA-Z-0-9éñúóáíÑÁÉÓÚÍ ]{3,30}" v-model="firstName" required>
                </div>
                <div class="text-left my-3" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                  <label class="label-input" style="font-size:15px;color:black;color: #6E6E6E;">Segundo Nombre:</label>
                  <input type="text" id="Input_LastName" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                  minlength="3" maxlength="30" pattern="[a-zA-Z-0-9éñúóáíÑÁÉÓÚÍ ]{3,30}" v-model="lastName">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1">
                <div class="row" style="margin-left:24px; margin-top:0px;margin-right:10px">
                  <div class="center">
                    <div class="text-left my-3">
                      <label class="label-input" style="font-size:15px;color:black;color: #6E6E6E;">Fecha de Nacimiento:</label>
                      <!-- <input  type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" id="Input_BirthDate"
                      minlength="4" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{}" v-model="birthDate" placeholder="Seleccionar fecha"> -->
                      <date-picker
                        simple
                        color="#810042"
                        placeholder="Fecha nacimiento"
                        locale="es"
                        input-format="DD/MM/YYYY"
                        v-model="birthDate"
                        @change="selecrDate"
                        id="birthDate"
                        :localeConfig="{
                          es: {
                            displayFormat: 'DD/MM/YYYY',
                              lang: {
                                year: 'Año',
                                month: 'Mes',
                                day: 'Día',
                                label: '',
                                submit: 'Seleccionar',
                                cancel: 'Cancelar',
                                now: 'Hoy',
                                nextMonth: 'Siguiente',
                                prevMonth: 'Anterior',
                              },
                            }
                        }" />
                    </div>
                    <div class="text-left my-3">
                      <label class="label-input" style="text-align: left;font-size:15px;color:black;color: #6E6E6E;">Porcentaje:</label>
                      <div class="flex w-full sm:w-24 text-sm pl-7 pr-12 font-light shadow-md border-2 rounded-lg px-2 focus:border-blue-700 focus:border-blue-700 block pl-7 pr-12 sm:text-sm rounded-md">
                        <input id="Input_Percentage" class="w-12" type="number" style="outline:none" v-on:change="percentage=changePercentage(percentage)" v-model="percentage" required :min="1" :max="100">
                        <span tabIndex="-1" class="input-group-addon mt-auto py-3" style="background-color:#ffffff; margin-top:0px;">%</span>
                      </div>
                    </div>
                    <div class="text-left my-3">
                      <label class="label-input-radio" style="font-size:15px;color:black;color: #6E6E6E;">Parentesco:</label>
                      <select lang="en" id="Select_Relationship" translate="no" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" ref="stateSelected" required v-model="kinship">
                        <option value="CONYUGE">Conyuge</option>
                        <option value="HIJO">Hijo</option>
                        <option value="PADRE">Padre</option>
                        <option value="MADRE">Madre</option>
                        <option value="HERMANO">Hermano</option>
                        <option value="ABUELO">Abuelo</option>
                        <option value="TIO">Tio</option>
                        <option value="PAREJA">Pareja</option>
                        <option value="SOBRINO">Sobrino</option>
                      </select>
                    </div>
                    <div class="row">
                      <button class="next_button outline-none" type="submit" style="" id="Button_addBeneficiarie">{{ id==0 ? 'Agregar' : 'Editar' }}</button>
                      <input type="button" v-show="id!=0" v-on:click="cancelModifyBeneficiarie()" class="back_button" id="Button_cancelBeneficiarie" value="Cancelar edición">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br>
        <center>
            <div class="loader" id="divLoading" style="width:0px;height:0px;visibility:collapse;display: none;"></div>
        </center>
      </form>

      <div class="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1">
        <div class="row" style="margin-left:24px; margin-top:0px;margin-right:10px">
          <div class="center" v-if="this.listBeneficiaries.length > 0">
            <br>
            <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
              <div class="row">
                <center>
                  <p class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-5xl title" style="margin-top:0px;">
                      Beneficiarios</p>
                </center>
              </div>
            </div>
            <div class="justify-center">
              <div style="">
                <table class="width200" style="color: #FFFFFF">
                  <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Apellidos</th>
                    <th>Fec. de Nac</th>
                    <th>%</th>
                    <th>Parentesco</th>
                    <th>Acciones</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="obj in this.listBeneficiaries" :key="obj.id">
                      <td style="color: black"><p style="text-transform: uppercase;">{{obj.firstName+" "+obj.lastName}}</p></td>
                      <td style="color: black"><p style="text-transform: uppercase;">{{obj.paternal+" "+obj.maternal}}</p></td>
                      <td style="color: black"><p>{{orderDateByDayMonthYear(obj.birthDate)}}</p></td>
                      <td style="color: black"><p>{{obj.percentage}}%</p></td>
                      <td style="color: black"><p style="text-transform: uppercase;">{{obj.kinship}}</p></td>
                      <td style="color: black">
                        <button v-on:click="modifyBeneficiarie(obj.id)" style="text-decoration: none;"><img height="35" width="35" src="../../assets/Cuenta/edit-icon.svg"></button>
                        <button v-on:click="removeBeneficiarie(obj.firstName+obj.lastName+obj.paternal+obj.maternal,obj.birthDate,obj.percentage, false)" style="text-decoration: none;margin-left:5px;"><img height="35" width="35" src="../../assets/Cuenta/delete-icon.svg"></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display:flex;justify-content:center;">
        <div id="divButtonNext" style="visibility:hidden">
          <button class="next_button" v-on:click="registerBeneficiaries()">Siguiente</button>
        </div>
      </div>
      <div style="display:flex;justify-content:center;margin-top:10px;">
        <div>
          <button class="back_button" v-on:click="goToBack()" type="button" id="">Atrás</button>
        </div>
      </div>
      <br>
    </div>
  </div>
  <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
  <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
</div>
</template>


<script>
import('@/assets/css/stylesheet.css')
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";
import { saveRequest,getAdmissionRequest,updateOnlyStatus } from "@/api/user";
import moment from "moment";
import { getTokenDecoden } from "@/helpers/tokenauth";

export default {
  data() {
    return {
      errors: [],
      listBeneficiaries: [],
      id:0,
      paternal: "",
      maternal: "",
      firstName: "",
      lastName: "",
      birthDate: "",
      percentage: "0",
      kinship: "",
      personId: null,
      member_id: "",
      birthday: "",
      titularBirthday: "",
      registerExist: false,
      emailUser: "",
      isLoading: false,
      maxDate: "",
      minYearDatePicker: "",
      multiApi: process.env.VUE_APP_MULTIAPI,
      admission_request_id:null,
      user_id:null,
      admission_status:null,
    };
  },
  name: "Beneficiarios",
  components: {
    MenuLateral,
    Loading,
  },
  props: {
    msg: String,
  },
  async mounted() {

    document
      .getElementById("Input_Paternal")
      .addEventListener("keyup", ({ detail }) => {
        var regex = /^[a-zA-ZÀ-ÿ]+$/;

        if (regex.test(this.paternal) != true) {
          this.paternal = this.paternal.replace(/[^a-zA-ZÀ-ÿ@\s]+/, "");
        }
      });

        document.getElementById("Input_Maternal").addEventListener('keyup', ({ detail }) => {
            var regex = /^[a-zA-ZÀ-ÿ]+$/;
            
            if (regex.test(this.maternal) != true) {
                this.maternal = this.maternal.replace(/[^a-zA-ZÀ-ÿ@\s]+/, '');
            }
        });

        document.getElementById("Input_FirstName").addEventListener('keyup', ({ detail }) => {
            var regex = /^[a-zA-ZÀ-ÿ]+$/;
            
            if (regex.test(this.firstName) != true) {
                this.firstName = this.firstName.replace(/[^a-zA-ZÀ-ÿ@\s]+/, '');
            }
        });

        document.getElementById("Input_LastName").addEventListener('keyup', ({ detail }) => {
            var regex = /^[a-zA-ZÀ-ÿ]+$/;
            
            if (regex.test(this.lastName) != true) {
                this.lastName = this.lastName.replace(/[^a-zA-ZÀ-ÿ@\s]+/, '');
            }
        });
    },
    async beforeMount() {
        await this.loadInfo();
        this.establisDateMaxToday();
        this.establishYearMinDatePicker();
        this.abilityButtonNext();
        
        Array.prototype.max = function() {
            return Math.max.apply(null, this);
        };
    },
    methods:{
        abilityButtonNext(){
          document.getElementById("divButtonNext").style.visibility = "hidden"
          if(this.listBeneficiaries.length > 0){
            document.getElementById("divButtonNext").style.visibility = "visible"
          }
        },
        Logout(){
            sessionStorage.clear();
            this.$router.push({path:"/"});
        },
        validateAgeRelationship(kinship){
          const beneficiarieDate = moment(this.orderDateByYearMonthDay(this.birthDate, '/')).format("YYYY/MM/DD");
          const titularDate = moment(this.titularBirthday).format("YYYY/MM/DD");
          let ageOwner = this.getAge(titularDate);
          let ageBeneficiary = this.getAge(beneficiarieDate);

          if (["MADRE","PADRE","ABUELO"].includes(kinship) && ((ageOwner+12) > ageBeneficiary)) {
            return true;
          }
          if (kinship == "HIJO" && ((ageOwner-12) < ageBeneficiary)) {
            return true;
          }
          return false;
        },
        addBeneficiarie(e){
          
            e.preventDefault();
            this.isLoading = true
            if(this.percentage == "0"){
                Swal.fire({
                    title: "Aviso",
                    text: "El porcentaje del beneficiario no puede ser 0",
                    icon: "warning",
                    confirmButtonColor: '#FEB72B',
                });
                this.isLoading = false
                return false;
            }

            if (this.validateEmptyDate(this.birthDate)) {
                Swal.fire({
                    title: "Aviso",
                    text: "Favor de seleccionar una fecha en Fecha de Nacimiento.",
                    icon: "warning",
                    confirmButtonColor: '#FEB72B',
                });
                this.isLoading = false
                return false;
            }
            
            if(this.validateAgeRelationship(this.kinship)){
              const message = `Edad del beneficiario '${this.kinship}' invalida con relación a edad del socio.`;
                Swal.fire({
                  title: "Aviso",
                  text: message,
                  icon: "warning",
                  confirmButtonColor: '#FEB72B',
                });
                this.isLoading = false;
                return;
            }
            
            if(this.paternal =="" && this.maternal == ""){        
              Swal.fire({
                title: "Aviso",
                text:
                  "Favor de capturar por lo menos un apellido del beneficiario",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              });
              this.isLoading = false
              return false;
            }
            
            // console.log("Hola",this.birthDate)
            var beneficiarie = {
                id: this.id == 0 ? this.getMaxIdBeneficiarie() + 1 : this.id,
                paternal: this.paternal.trim(),
                maternal: this.maternal.trim(),
                firstName: this.firstName.trim(),
                lastName: this.lastName != "" ? this.lastName.trim() : this.lastName,
                birthDate: (this.orderDateByYearMonthDay(this.birthDate, '/')),
                percentage: this.percentage,
                kinship: this.kinship
            }

            // console.log("beneficiarios: "  + JSON.stringify(beneficiarie))
            
            var addBeneficiariee = true
            if(this.getPercentageBeneficiarie(beneficiarie.id) > 100){
                addBeneficiariee = false
                Swal.fire({
                    title: "Aviso",
                    text: "El porcentaje entre todos los beneficiarios debe ser 100%",
                    icon: "warning",
                    confirmButtonColor: '#FEB72B',
                });
                this.isLoading = false
                //this.cleanControls();
            }

            if(addBeneficiariee){
                this.isLoading = false
                if (this.id != 0) {
                    this.modifyBeneficiarieArray(beneficiarie);
                } else {
                    this.listBeneficiaries.push(beneficiarie);
                    // console.log(this.listBeneficiaries)
                }
                this.cleanControls();
            }
        },
        getMaxIdBeneficiarie() {
            let idArray = []
            if (this.listBeneficiaries.length > 0) {
                for (let i = 0; i < this.listBeneficiaries.length; i++) {
                    idArray.push(this.listBeneficiaries[i].id);
                }
            } else {
                return 0
            }

            let max = idArray.max()
            return max
        },
        getAge(_birthdate) {
          var birthdate = new Date(_birthdate)
          const today = new Date();
          const age = today.getFullYear() - birthdate.getFullYear() - 
                    (today.getMonth() < birthdate.getMonth() || 
                    (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate()));
          return age;
        },
        cancelModifyBeneficiarie() {
            this.cleanControls();
        },
        modifyBeneficiarieArray(beneficiare){
            let index = this.listBeneficiaries.findIndex(b => b.id === beneficiare.id);
            this.listBeneficiaries[index] = beneficiare
        },
        modifyBeneficiarie(id){
          // console.log("Modificar beneficiario:", id);
            var obj_beneficiarie = this.getBeneficiarie(id);
            if(obj_beneficiarie != null){
                this.id = obj_beneficiarie.id
                this.paternal = obj_beneficiarie.paternal
                this.maternal = obj_beneficiarie.maternal
                this.firstName = obj_beneficiarie.firstName
                this.lastName = obj_beneficiarie.lastName
                this.birthDate = this.orderDateByDayMonthYear(obj_beneficiarie.birthDate, '/')
                //this.birthDate = moment(obj_beneficiarie.birthDate).format("DD/MM/YYYY")
                //console.log("Fecha de nacimiento",this.birthDate)
                this.percentage = obj_beneficiarie.percentage
                this.kinship = obj_beneficiarie.kinship.toUpperCase();
                //this.removeBeneficiarie(obj_beneficiarie.firstName+obj_beneficiarie.lastName+obj_beneficiarie.paternal+obj_beneficiarie.maternal, obj_beneficiarie.birthDate, obj_beneficiarie.percentage, true)
            }
        },
        removeBeneficiarie(fullName, dateBirth, percentage, isModify){
          // console.log("Entre a remover",fullName);
            for (var i =0; i < this.listBeneficiaries.length; i++){
                if (this.listBeneficiaries[i].firstName+this.listBeneficiaries[i].lastName+this.listBeneficiaries[i].paternal+
                this.listBeneficiaries[i].maternal == fullName && this.listBeneficiaries[i].birthDate == dateBirth &&
                this.listBeneficiaries[i].percentage == percentage)
                {
                  if(isModify){
                        this.listBeneficiaries.splice(i,1);
                    }
                    else
                    {
                        Swal.fire({
                            title: "Aviso",
                            text: "¿Desea eliminar el beneficiario?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "SI",
                            cancelButtonText: "NO",
                            confirmButtonColor: '#FEB72B',
                            cancelButtonColor: '#FEB72B',
                        })
                        .then((result) => {
                            if (result.value) {
                                this.listBeneficiaries.splice(i,1);
                                this.cleanControls();
                            }
                            this.abilityButtonNext();
                        });
                    }
                    break;
                }
            }
        },
        getBeneficiarie(id){
            var obj_beneficiarie = null
            for (var i =0; i < this.listBeneficiaries.length; i++){
                if (this.listBeneficiaries[i].id === id) {
                    obj_beneficiarie = this.listBeneficiaries[i]
                    break;
                }
            }
            return obj_beneficiarie
        },
        async registerBeneficiaries(){
            this.isLoading = true
            var listBen = []
            var correcto = true
            this.cleanControls();
            
            var porcentage = 0
            if(this.listBeneficiaries.length > 0){
              // console.log("Entre a registtrar beneficiarios");
                for (let objBeneficiarie of this.listBeneficiaries) {
                    porcentage += parseInt(objBeneficiarie.percentage)
                }
            }
            if(porcentage != 100){
                Swal.fire({
                    title: "Aviso",
                    text: "El porcentaje entre todos los beneficiarios debe ser 100%",
                    icon: "warning",
                    confirmButtonColor: '#FEB72B',
                });
                this.isLoading = false
                correcto = false
            }

            if (correcto == true && this.listBeneficiaries.length > 0) {
              await this.createObjectBeneficiaries();
              this.isLoading = false;
              this.$router.push({path:"carga-documentos/0"});
            }
    },
    cleanControls() {
      this.id = 0;
      this.paternal = "";
      this.maternal = "";
      this.firstName = "";
      this.lastName = "";
      this.birthDate = "";
      this.percentage = "";
      this.kinship = "";
      document.getElementById('vpd-birthDate').value = ""
    },
    validateEmptyDate(date) {
      if (date == "" || date == null) {
        return true;
      }

      return false;
    },
    getPercentageBeneficiarie(id) {

      var percentage = 0;
      if (this.listBeneficiaries.length > 0) {
        for (let objBeneficiarie of this.listBeneficiaries) {

          if(objBeneficiarie.id != id){
            percentage += parseInt(objBeneficiarie.percentage);
          }
        }
      }
      return percentage + parseInt(this.percentage);
    },
    async Next() {
      this.$router.push({path:"/terminos-y-condiciones"});
    },
    calculateNumberEntryTwoDates(date) {
      var dateToday = new Date();
      var dateAdminision = new Date(date);

      var antiguedadd = dateToday.getFullYear() - dateAdminision.getFullYear();

      dateAdminision.setFullYear(dateAdminision.getFullYear() + antiguedadd);

      if (dateAdminision > dateToday) {
        antiguedadd--;
      }
      return antiguedadd;
    },
    async encriptarMensaje(mensaje) {
      const header = { alg: "HS256", typ: "JWT" };
      const encodedHeader = Buffer.from(JSON.stringify(header)).toString(
        "base64"
      );

      var data = {
        html: mensaje,
      };
      const encodedPayload = Buffer.from(JSON.stringify(data)).toString(
        "base64"
      );
      var jwtSecret = "vM22%!SmnjNV";
      const crypto = require("crypto");
      const signature = crypto
        .createHmac("sha256", jwtSecret)
        .update(encodedHeader + "." + encodedPayload)
        .digest("base64");
      var tokenMessage = encodedHeader + "." + encodedPayload + "." + signature;
      return tokenMessage;
    },
    changePercentage(value) {
      if (value.toString().includes(".")) {
        value = parseFloat(value);
      }

      var value = parseInt(value);
      if (value < 1 || value > 100) {
        Swal.fire({
          title: "Aviso",
          text:
              "El porcentaje debe estar entre 1 y 100",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        value = 0;
      }
      return value;
    },
    establisDateMaxToday() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      today = yyyy + "-" + mm + "-" + dd + " 00:00:00";
      this.maxDate = today;
    },
    establishYearMinDatePicker() {
      var today = new Date();
      var yyyy = today.getFullYear() - 100;

      this.minYearDatePicker = yyyy;
    },
    formatMoney(value) {
      const options2 = { style: "currency", currency: "MXN" };
      const formatter = new Intl.NumberFormat("en-US", options2);
      var resultado = formatter.format(value);
      resultado = resultado.replace("MX", "");
      return resultado;
    },
    formatDate(date) {
      var d = new Date(date);
      var month = "" + (d.getMonth() + 1);
      var day = "" + d.getDate();
      var year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
    addUpperCase(value) {
      if (value != "") {
        value = value.toUpperCase();
      }
      return value;
    },
    addLowerCase(value) {
      if (value != "") {
        value = value.toLowerCase();
      }
      return value;
    },
    orderDateByDayMonthYear(date) {
      return moment(date).format("DD/MM/yyyy");
    },
    orderDateByYearMonthDay(date, typeSplit) {
      return moment(date.split(' ')[0].split(typeSplit).reverse().join("-")).format("YYYY-MM-DD");
    },
    async loadInfo() {
      var auth = getTokenDecoden();
      this.user_id = auth["id"];
      this.personId = auth.obj.person["id"];
      this.emailUser = auth.obj.person["email"];

      await this.searchPersonalData();
    },
    async searchPersonalData() {
      let response = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      
      if(Object.entries(response).length != 0)
      {
        await this.getDataAdmissionUser(response);
      }else {
        await this.getAdmissionRequest();
      }
    },
    async getDataAdmissionUser() {
      let response = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      if(Object.entries(response).length != 0)
      {
        this.admission_request_id = response.data.response.admission_request.id;

        this.titularBirthday = response.data.response.admission_request.admission_information.birthday

        this.admission_status = response.data.response.admission_request.status;  //se obtiene el estatus de la solicitud
        if(response.data.response.admission_request.admission_information.beneficiaries_attributes["0"].status != "")
        {
          this.listBeneficiaries = [];
          let beneficiaries = response.data.response.admission_request.admission_information.beneficiaries_attributes;
          if(beneficiaries)
          {
            for (let objBen in beneficiaries) {
              var json = {
                id: this.listBeneficiaries.length + 1,
                paternal: beneficiaries[objBen].paternal_name,
                maternal: beneficiaries[objBen].maternal_name,
                firstName: beneficiaries[objBen].name,
                lastName: beneficiaries[objBen].middle_name ? beneficiaries[objBen].middle_name : "",
                birthDate: beneficiaries[objBen].birthday,
                percentage: beneficiaries[objBen].percentage,
                kinship: beneficiaries[objBen].relationship,
              };
              this.listBeneficiaries.push(json);
            }
          }
        }
      }
    },
    async createObjectBeneficiaries() {
      
      var infoJson = this.buildJson();


      if(this.admissionStatusIsValidToSave(this.admission_status))
      {
        await this.saveRequest(infoJson);
      }
 
    },
    async getAdmissionRequest(){
      await getAdmissionRequest(
        sessionStorage.getItem("login"),
        this.personId
      )
      .then((response) => {
        this.getDataAdmissionUser(response);
      })
      .catch((error) => {
        Swal.fire({
          title: "Aviso",
          text:
            "Se presentó un error. No fue posible obtener los datos de la solicitud.",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
        // window.location.href = "/cuenta/registro-datos-personales";
        this.$router.push({path:"/cuenta/registro-datos-personales"});
      });
    },
    async saveRequest(jsonData){
      this.isLoading=true;
      jsonData.token_auth = sessionStorage.getItem("login");
      await saveRequest(
        jsonData
      ).then((response) => {
        this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
        console.log("Solicitud guardada con éxito");
      })
      .catch((error) => {
        console.log(
          "Se presento un error al guardar la información personal: ",
          error
        );
      }).finally( () => {this.isLoading=false})
    },
    buildJson() {
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);

      json.data.response.admission_request.admission_information.beneficiaries_attributes = {};
      let obj_beneficiarie = {},
        index = 0;
      for (let beneficiarie of this.listBeneficiaries) {
        obj_beneficiarie = {
          paternal_name: this.addUpperCase(beneficiarie.paternal),
          maternal_name: this.addUpperCase(beneficiarie.maternal),
          name: this.addUpperCase(beneficiarie.firstName),
          middle_name: beneficiarie.lastName ? this.addUpperCase(beneficiarie.lastName) : beneficiarie.lastName,
          gender: "",
          percentage: beneficiarie.percentage,
          status: "activo",
          birthday: beneficiarie.birthDate,
          relationship: beneficiarie.kinship.toLowerCase(),
        };
        json.data.response.admission_request.admission_information.beneficiaries_attributes[
          index.toString()
        ] = obj_beneficiarie;
        index++;
      }

      this.setPersonalInfoToLocalStorage('admissionUser'+this.user_id, json)

      return {
        token_auth: sessionStorage.getItem("login"),
        admission_request_id: this.admission_request_id,
        user_id: this.user_id,
        change_for: "beneficiarie_info",
        admission_request_params: {
          admission_information: json.data.response.admission_request.admission_information,
        },
      };
    },
    getJsonFromLocalStorageBy(user_id) {
      return JSON.parse(window.sessionStorage.getItem(user_id));
    },
    setPersonalInfoToLocalStorage(user_id, jsonPersonalInfo) {
      sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
    },
    async goToBack() {
      await updateOnlyStatus(sessionStorage.getItem("login"), this.admission_request_id, "update_saving_info").then(response => {
        this.$router.push({path:"/cuenta/ahorro"});
      });
    },
    admissionStatusIsValidToSave( status ) {
      const validStatuses = [
        "autorizo_descuento_aceptado",
        "video_completo",
        "asignada",
        "en_revision",
        "rechazada",
        "cancelada"
      ];

      return (validStatuses.find(value => value == status))? false: true;
    },
    selecrDate(event){
      if((moment(event._d).format("YYYY-MM-DD"))> (moment().format("YYYY-MM-DD"))){
        Swal.fire({
          title:"Aviso",
          text:"La fecha de nacimiento tiene que ser menor a la actual",
          icon:"warning",
          confirmButtonColor:"#FEB72B"
        });
        this.birthDate=null;
        return
      }else{
        this.birthDate = moment(event._d).format("DD/MM/YYYY")
      }
      // document.getElementById('vpd-birthDate').value = this.birthDate

      // this.birthDate = moment(event._d).format("YYYY-MM-DD")
      // document.getElementById('vpd-birthDate').value = this.birthDate
    },
  },
};
</script>

<style>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
  .border {
    border-right: 1px dashed #333;
    border-top: 1px dashed #ffffff;
    border-left: 1px dashed #ffffff;
    border-bottom: 1px dashed #ffffff;
  }
}

@media (max-width: 766px) {
  .border {
    margin-left: 9px;
    margin-right: -15px;
    border-right: 1px dashed #ffffff;
    border-top: 1px dashed #ffffff;
    border-left: 1px dashed #ffffff;
    border-bottom: 1px dashed #ffffff;
  }
}
    #Button_addBeneficiarie{
        width: 150px;
        height: 30px;
        margin-top:5px;
        padding-top: 0%;
    }

    #Button_cancelBeneficiarie{
        width: 170px;
        height: 30px;
        margin-left: 5px;
        margin-top:5px;
        padding-top: 0%;
    }

#button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}
@media (max-width: 500px) {
  #button_popups {
    height: 50px !important;
  }
}
</style>

<style>
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even){background-color: #ffffff}

th {
  background-color: #ffffff;

}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #FDC860; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ui-datepicker { z-index: 10000 !important; }

h2 {font-size:36px;margin:0 0 10px 0}
p {margin:0 0 10px 0}

table.width200,table.rwd_auto {border:1px solid #ccc;width:100%;margin:0 0 50px 0}
    .width200 th,.rwd_auto th {background:#810042;padding:5px;text-align:center;}
    .width200 td,.rwd_auto td {border-bottom:1px solid #ccc;padding:5px;text-align:center}
    .width200 tr:last-child td, .rwd_auto tr:last-child td{border:0}
/*
.rwd {width:100%;overflow:auto;}
    .rwd table.rwd_auto {width:auto;min-width:100%}
        .rwd_auto th,.rwd_auto td {white-space: nowrap;} */

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

@media only screen and (max-width: 1024px)
{
    table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }

    .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }

    .width200 tr { border: 1px solid #ccc; }

    .width200 td { border: none;border-bottom: 1px solid #ccc; position: relative;padding-left: 40%;text-align:left }

    .width200 td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}

    .width200 td:nth-of-type(1):before { content: "Nombre:"; }
    .width200 td:nth-of-type(2):before { content: "Apellidos:"; }
    .width200 td:nth-of-type(3):before { content: "Fec. de Nac:"; }
    .width200 td:nth-of-type(4):before { content: "%:"; }
    .width200 td:nth-of-type(5):before { content: "Parentesco:"; }
    .width200 td:nth-of-type(6):before { content: "Acciones:"; }

    .descarto {display:none;}
    .fontsize {font-size:10px}
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width : 320px) and (max-width : 480px)
{
    .descarto {display:none;}
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px)
{
    .descarto {display:none;}
    .fontsize {font-size:10px}
}

.vpd-input-group input{
  border: 2px solid rgba(205, 205, 205, 0.416) !important;
  border-start-end-radius: 10px;
  border-end-end-radius: 10px;
  padding: 5px !important;
  box-shadow: 0px 5px 10px 0px  rgba(205, 205, 205, 0.416) !important;
}
</style>